<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Subcatgorias - Inclusão</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-subcategories'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>
    <form-product-subcategory :btnLabel="btnLabel"></form-product-subcategory>
</span>
</template>

<script>
import FormProductSubcategoryComponent from "./partial/FormProductSubcategoryComponent";

export default {
    name: "AddProductSubcategoryComponent",
    data() {
        return {
            btnLabel: 'Incluir'
        }
    },
    methods: {

    },
    components: {
        formProductSubcategory: FormProductSubcategoryComponent,
    }
};
</script>

<style scoped>

</style>
